@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

@font-face {
  font-family: "Formula1-Bold";
  src: url(./fonts/Formula1-Bold.ttf) format('truetype');
}

:root {
  --f1-red: #FF232B;
  --f1-red-active: #ba1319;
  --f1-green: #52E252;
  --f1-black: #010000;
  --f1-orange: #FF8000;
}

* {
  font-family: "Inter", sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.f1-text {
  font-family: "Formula1-Bold";
}

.red {
  color: var(--f1-red) !important;
}